<div class="nav-bar h-full flex gap-2">
    <!-- && !hasLimitedAccess() -->
    <ng-container *ngIf="isCPP() || isFab() || isOperator() || isClient()">
        <a class="item-menu h-full" [routerLink]="appRoutes.home">
            <button mat-button [ngClass]="{'selected': isCurrentUrl(appRoutes.home)}">{{ "MENU.PROJECTS" | translate }}</button>
        </a>
    </ng-container>
    <ng-container *ngIf="isCPP() || isFab() || isOperator()">
        <button mat-button [matMenuTriggerFor]="AdminMenu" [ngClass]="{'selected': isCurrentUrl(appRoutes.admin, true, [appRoutes['patch-notes']])}">{{ "MENU.MANAGE" | translate }}</button>
        <mat-menu #AdminMenu="matMenu">
            <ng-container *ngIf="isCPP() || isFab()">
                <a class="item-menu h-full" [routerLink]="appRoutes.printers">
                    <button mat-menu-item [ngClass]="{'selected': isCurrentUrl(appRoutes.printers)}">{{ "MENU.PRINTER" | translate }}</button>
                </a>
            </ng-container>
            <ng-container *ngIf="isCPP() || isFab() || isOperator()">
                <a class="item-menu h-full" [routerLink]="appRoutes.colors">
                    <button mat-menu-item [ngClass]="{'selected': isCurrentUrl(appRoutes.colors)}">{{ "MENU.COLORS" | translate }}</button>
                </a>
            </ng-container>
            <ng-container *ngIf="isCPP()">
                <a class="item-menu h-full" [routerLink]="appRoutes.users">
                    <button mat-menu-item [ngClass]="{'selected': isCurrentUrl(appRoutes.users)}">{{ "MENU.USERS" | translate }}</button>
                </a>
                <a class="item-menu h-full" [routerLink]="appRoutes.groups">
                    <button mat-menu-item [ngClass]="{'selected': isCurrentUrl(appRoutes.groups)}">{{ "MENU.GROUPS" | translate }}</button>
                </a>
                <a class="item-menu h-full" [routerLink]="appRoutes.brands">
                    <button mat-menu-item [ngClass]="{'selected': isCurrentUrl(appRoutes.groups)}">{{ "MENU.MARQUES" | translate }}</button>
                </a>
            </ng-container>
        </mat-menu>
    </ng-container>
    <ng-container *ngIf="isCPP()">
        <a class="item-menu h-full" [routerLink]="appRoutes.stats">
            <button mat-button [ngClass]="{'selected': isCurrentUrl(appRoutes.stats)}">{{ "MENU.STATS" | translate }}</button>
        </a>
    </ng-container>
    <ng-container *ngIf="!isClient()">
        <mat-divider vertical class="h-full"></mat-divider>
        <a class="item-menu h-full" [href]="appRoutes.kiosk" target="_blank">
            <button mat-button [ngClass]="{'selected': isCurrentUrl(appRoutes.kiosk)}"> {{ "MENU.KIOSK" | translate }} </button>
        </a>
    </ng-container>
    <ng-container *ngIf="isCPP() || isFab() || isOperator()">
        <mat-divider vertical class="h-full"></mat-divider>
        <a class="item-menu h-full" [routerLink]="appRoutes['patch-notes']">
            <button mat-button [ngClass]="{'selected': isCurrentUrl(appRoutes['patch-notes'])}">
                {{ "MENU.PATCH_NOTES" | translate }}
            </button>
        </a>
    </ng-container>
    <ng-container *ngIf="isCPP() || userEasydiadem || isOperator()">
        <mat-divider vertical class="h-full"></mat-divider>
        <a class="item-menu h-full" [routerLink]="appRoutes['ed-dashboard']">
            <button mat-button [ngClass]="{'selected': isCurrentUrl(appRoutes['ed-dashboard'])}">
                <mat-icon [svgIcon]="'ed-hand'" class="text-tw-text-dark !mr-2"></mat-icon>
                <div class="flex">{{ "MENU.EASYDIADEM" | translate}}</div>
            </button>
        </a>
    </ng-container>
    <ng-container *ngIf="isClient()" >
        <mat-divider vertical class="h-full"></mat-divider>
            <button mat-button [matMenuTriggerFor]="SupportMenu">
                <mat-icon class='text-tw-primary'>help</mat-icon>
                <div class="flex">{{ "MENU.SUPPORT" | translate }}</div>
                </button>
            <mat-menu #SupportMenu="matMenu">
                <a class="item-menu h-full">
                    <button mat-menu-item (click)="userManual()">
                        <div class="flex">{{ "USER_MANUAL" | translate}}</div>
                    </button>
                </a>
                    <a class="item-menu h-full"  [href]="'mailto:' + environment.contact.email">
                    <button mat-menu-item>{{ "MENU.CONTACT" | translate }}</button>
                </a>
            </mat-menu>
    </ng-container>



    <mat-divider vertical class="h-full"></mat-divider>
        <button mat-button [matMenuTriggerFor]="AccountMenu" class="flex flex-row justify-center items-center" [ngClass]="{'selected': isCurrentUrl(appRoutes.account)}">
            <mat-icon class="mr-2">account_circle</mat-icon>
            <div class="flex">{{ userFullName && userFullName !== "" ? userFullName : ("MENU.ACCOUNT" | translate) }}</div>
        </button>    
    <mat-menu #AccountMenu="matMenu">
        <a class="item-menu h-full" [routerLink]="appRoutes.account">
            <button mat-menu-item>
                <div class="flex">{{ "MENU.PROFIL" | translate}}</div>
            </button>
        </a>
            <button class="item-menu h-full text-tw-secondary" (click)="logout()"  mat-menu-item>{{ "MENU.LOG_OUT" | translate }}</button>
    </mat-menu>


    <!-- <mat-divider vertical class="h-full"></mat-divider> -->
    <!-- <a class="item-menu h-full" [routerLink]="appRoutes.account">
        <button mat-button class="flex flex-row justify-center items-center" [ngClass]="{'selected': isCurrentUrl(appRoutes.account)}">
            <mat-icon class="mr-2">account_circle</mat-icon>
            <div class="flex">{{ userFullName && userFullName !== "" ? userFullName : ("MENU.ACCOUNT" | translate) }}</div>
        </button>
    </a> -->


    <!-- <button mat-button class="!h-full content-fit no-bar" [matTooltip]="'MENU.LOG_OUT' | translate" (click)="logout()">
        <mat-icon class="!text-tw-error m-0 p-0 mx-2">power_settings_new</mat-icon>
    </button> -->
    <app-notif-list class="flex h-full"></app-notif-list>
    <div class="item-menu-search flex flex-row justify-center items-center" (mouseenter)="searchOnMouseEnter()" (mouseleave)="searchOnMouseLeave()">
        <input #searchInput class="flex flex-col justify-center items-center !outline-none" [placeholder]="('SEARCH' | translate) + '...'" (keyup.enter)="makeSearch()" [(ngModel)]="sampleModel" (ngModelChange)="updateDisplay()" />
        <mat-icon #searchIcon class="!flex flex-col justify-center items-center" (click)="makeSearch()">search</mat-icon>
    </div>
</div>